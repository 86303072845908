import React from "react";

interface Props {
    fill: string;
}

export const EconomyIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill={fill}>
            <path d="M14.5,0.3C7.6,0.3,1.9,5.9,1.9,12.8s5.6,12.6,12.6,12.6s12.6-5.6,12.6-12.6S21.4,0.3,14.5,0.3z M14.5,22
		    c-5.1,0-9.2-4.1-9.2-9.2c0-5.1,4.1-9.2,9.2-9.2c5.1,0,9.2,4.1,9.2,9.2C23.7,17.9,19.6,22,14.5,22z"/>
            <path d="M17.9,16.2c-0.2,0.4-0.6,0.8-1,1c-0.4,0.3-0.9,0.5-1.5,0.6V19h-1.8v-1.2c-0.5-0.1-1-0.3-1.4-0.6c-0.4-0.3-0.8-0.6-1-1.1
            c-0.3-0.4-0.4-0.9-0.5-1.4l1.8-0.3c0.1,0.5,0.4,1,0.8,1.3c0.4,0.3,0.8,0.5,1.4,0.5c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.6-0.4
            c0.2-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.4-0.3-0.7-0.4l-2.4-0.7c-0.7-0.2-1.2-0.5-1.5-1
            c-0.3-0.4-0.5-0.9-0.5-1.6c0-0.5,0.1-0.9,0.3-1.3c0.2-0.4,0.5-0.7,0.9-1c0.4-0.3,0.8-0.4,1.4-0.5V6.7h1.8v1.2
            c0.7,0.2,1.3,0.5,1.7,0.9c0.5,0.5,0.8,1.1,1,1.8L16.3,11c-0.1-0.4-0.3-0.8-0.7-1.1c-0.3-0.3-0.8-0.4-1.2-0.4c-0.3,0-0.5,0-0.8,0.1
            c-0.2,0.1-0.4,0.2-0.5,0.4c-0.1,0.2-0.2,0.3-0.2,0.6c0,0.2,0.1,0.4,0.3,0.6c0.2,0.2,0.5,0.3,0.9,0.4l1.7,0.5c0.6,0.2,1,0.4,1.4,0.6
            c0.4,0.2,0.6,0.5,0.8,0.9c0.2,0.3,0.2,0.7,0.2,1.2C18.3,15.3,18.1,15.8,17.9,16.2z"/>
            <path d="M14.5,27.1C7.8,27.1,2.4,21.9,2,15.3c0,0.3,0,0.6,0,0.8c0,6.9,5.6,12.6,12.6,12.6s12.6-5.6,12.6-12.6c0-0.3,0-0.6,0-0.8
		    C26.6,21.9,21.2,27.1,14.5,27.1z"/>
        </svg>
    )
}