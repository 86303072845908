

import React from "react";

interface Props {
    fill: string;
}

export const IdeaIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill={fill}>
            <path d="M17.6,23.7h-6.2c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h6.2c0.4,0,0.8-0.4,0.8-0.8C18.4,24,18,23.7,17.6,23.7z" />
            <path d="M16,26.8H13c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8H16c0.4,0,0.8-0.4,0.8-0.8C16.8,27.2,16.5,26.8,16,26.8z" />
            <path d="M14.5,0.6C9.8,0.6,6,4.5,6,9.3c0,4.8,4.6,9.1,4.6,12h0c0,0.4,0.4,0.7,0.8,0.7h6.2c0.4,0,0.7-0.3,0.8-0.7h0
            c0-2.9,4.6-7.2,4.6-12C23,4.5,19.2,0.6,14.5,0.6z M17.6,15.2c-0.6,1-1.1,2.8-1.6,3.7h-3c-0.4-1-1-2.7-1.6-3.7
            c-1.1-1.9-2.3-4-2.3-5.9c0-3.1,2.4-5.6,5.4-5.6c3,0,5.4,2.5,5.4,5.6C19.9,11.2,18.7,13.3,17.6,15.2z"/>
        </svg>
    )
}