import React from "react";
import { DiagonalCoverNoRips } from "./DiagonalCoverNoRips";

interface Props {
    colour: ContainerColour;
    size: ContainerSize;
    id?: string,
    className?: string;
    direction?: Direction;
    withPad?: boolean;
}

export enum ContainerColour {
    BLUE = "blue",
    ORANGE = "orange",
    RR = "rr",
    RRFB = "rrfb",
    CC = "cc",
    HT = "ht",
    SGH = "sgh",
    BOOOM = "booom",
    RR2 = "rr2",
    SU = "su",
    SSS = "sss",
}

export enum Direction {
    LEFT = "left",
    RIGHT = "right",
}

export enum ContainerSize {
    LARGE = "large",
    MEDIUM = "medium",
    SMALL = "small",
}

export const DiagonalTopContainer: React.FC<Props> = ({ colour, size, id = "", className = "", direction = Direction.RIGHT, withPad = false, children }) => {
    return (
        <div id={id} className={`diagonal-container ${colour}-diagonal ${size}-diagonal ${direction}-diagonal ${withPad ? "with-pad" : ""} ${className} diagonal-top`}>
            <DiagonalCoverNoRips fill={"#ffffff"} className="top-cover" />
            <div className="content">
                {children}
            </div>
        </div>
    )
}