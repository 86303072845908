

import React from "react";

interface Props {
    fill: string;
}

export const GameDesignIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} className="icon">
            <path d="M11.6,5.5V5c0.6,0,1-0.4,1-1s-0.4-1-1-1h-5c-0.6,0-1,0.4-1,1s0.4,1,1,1v0.5c-2.4,1-4.1,3.3-4.1,6
            C2.5,15.1,5.4,18,9,18c3.6,0,6.5-2.9,6.5-6.5C15.5,8.8,13.9,6.5,11.6,5.5z M13.5,11.8c-1.1,0.3-2.1,0.5-3.1,0.3
            c-1-0.2-1.8-1-2.8-1.2c-0.9-0.2-2.1-0.2-3,0.2C4.8,8.8,6.7,7,9,7c2.5,0,4.5,2,4.5,4.5C13.5,11.6,13.5,11.7,13.5,11.8z"/>
            <path d="M7.6,2h3c0.6,0,1-0.5,1-1s-0.5-1-1-1h-3c-0.6,0-1,0.5-1,1S7,2,7.6,2z" />
        </svg>
    )
}