import React from "react";

interface Props {
    fill: string;
}

export const MarketingIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill={fill}>
            <path d="M4.6,18.1l1.1,5.5c0,0,0.3,1.7,1.8,1.7h1.7l-1.4-6.9L18,21.9v1.1c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6v-6.4h0.8
            c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4h-0.8V5.3c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6v1.1L6.9,10.1H3.7
            c-1.7,0-3.2,1.4-3.2,3.2v1.6c0,1.8,1.4,3.2,3.2,3.2H4.6z M18,18.5L10,15.8v-3.4L18,9.7V18.5z"/>
            <path d="M25.3,10.2L26.4,9c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0L24.2,9c-0.3,0.3-0.3,0.8,0,1.1
            C24.5,10.5,25,10.5,25.3,10.2z"/>
            <path d="M25.3,17.5c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l1.1,1.1c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1.1L25.3,17.5
            z"/>
            <path d="M27.7,13.1h-1.6c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8h1.6c0.4,0,0.8-0.4,0.8-0.8C28.5,13.4,28.1,13.1,27.7,13.1z"
            />
        </svg>
    )
}