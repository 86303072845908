import React from "react";

interface Props {
    fill: string;
}

export const GameDesignDocIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill={fill}>
            <path d="M19.2,0.5H6.7C5,0.5,3.6,1.9,3.6,3.6v21.8c0,1.7,1.4,3.1,3.1,3.1h15.6c1.7,0,3.1-1.4,3.1-3.1V6.7L19.2,0.5z M22.3,25.4H6.7
		    V3.6h7.8v6.2c0,0.9,0.7,1.6,1.6,1.6h6.2V25.4z M17.6,8.3V3.6l4.7,4.7H17.6z"/>
            <path d="M19.2,22.3H9.8c-0.9,0-1.6-0.7-1.6-1.6l0,0c0-0.9,0.7-1.6,1.6-1.6h9.4c0.9,0,1.6,0.7,1.6,1.6l0,0
		    C20.7,21.6,20,22.3,19.2,22.3z"/>
            <path d="M19.2,17.6H9.8c-0.9,0-1.6-0.7-1.6-1.6l0,0c0-0.9,0.7-1.6,1.6-1.6h9.4c0.9,0,1.6,0.7,1.6,1.6l0,0
		    C20.7,16.9,20,17.6,19.2,17.6z"/>
        </svg>
    )
}