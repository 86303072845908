import React from "react";
import { GameDesignIcon } from "../../components/layout/icons/GameDesignIcon";
import { IdeaIcon } from "../../components/layout/icons/IdeaIcon";
import { ResearchIcon } from "../../components/layout/icons/ResearchIcon";
import { GameDesignDocIcon } from "../../components/layout/icons/GameDesignDocIcon";
import { EconomyIcon } from "../../components/layout/icons/EconomyIcon";
import { LevelDesignIcon } from "../../components/layout/icons/LevelDesignIcon";
import { MarketingIcon } from "../../components/layout/icons/MarketingIcon";

export const gameDesignPageData: GameDevelopmentServiceData = {
    metaPageTitle: "Game Design - iDream Interactive",
    metaKeywords: "game design document, video game design, level design, video game ideas, video game marketing",
    metaDescription: "Find out everything from economy design, idea development, level design, design documents, research and marketing strategies when building a game.",
    title: "Game Design",
    subtitle: "Everything from economy design, idea development, level design, design documents, research and marketing strategy.",
    icon: <GameDesignIcon fill="#ffffff" />,
    services: [
        {
            title: "Idea Development",
            description: (<>
                <p>Whether your idea is fleshed out or not, we can handle your situation. We will go over various aspects pertaining to the theme, platform, genre, and target audience of the game.</p>
                <p>If you have a business goal or direction and wish to leave the entire creative process up to us, we can do that too.</p>
            </>),
            icon: <IdeaIcon fill="#080a2e" />,
            image: "",
            imageAlt: "Recipe Rescue Blue Chest",
        },
        {
            title: "Research",
            description: (<>
                <p>Once we have a solid understanding of the game idea, we dive deep into research. Are there other games out there with a similar idea? It is our job to determine if this has been done before.</p>
                <p>Game mechanics are hot topics in our social circle. It is our job to know what is new and trending. Nailing down a game mechanic, theme, concept, and differentiator is critical to a games success. We say this because it is extremely difficult to change down the road. </p>
                <p>Research is always the first step in Game Design. This is because we want to have good information to back up the rest of our decisions in a games design.</p>
            </>),
            icon: <ResearchIcon fill="#03041d" />,
            image: "",
            imageAlt: "Jim Morton and Friends",
        },
        {
            title: "Economy Design",
            description: (<>
                <p>While games are about having fun, it is just as important to understand how it will make you a return on your investment. Economy design is all about balance. Depending on the genre of game there are various ways to monetize.</p>
                <p>We put the power into the users hands when it comes to in-app purchasing. We believe that users should not feel a game is “pay to win”, but instead give them opportunities to make their in-game experience enhanced by purchasable content within the game.</p>
                <p>Introducing optional in-game items such as premium character cosmetics to balancing the in-game currency progression and overall game economy will help users feel good about playing the game.</p>
            </>),
            icon: <EconomyIcon fill="#080a2e" />,
            image: "",
            imageAlt: "Cart 'O' Coins",
        },
        {
            title: "Game Design Document",
            description: (<>
                <p>The purpose of a game design document is to have a simple, easy to read document that explains your product before it’s built.  This is the time to make changes or adopt different ideas.  We can then take those concepts and create prototypes using this document as a requirements manual.</p>
                <p>This document helps provide an overview of the game idea as a whole. It will answer questions such as how the game is played, how it is monetized, how it will look and feel, and what features are included in the game outside of the core mechanic.</p>
            </>),
            icon: <GameDesignDocIcon fill="#ffffff" />,
            image: "",
            imageAlt: "Silver Recipe Page",
        },
        {
            title: "Level Design",
            description: (<>
                <p>We believe in simplifying the level creation process through level builders and automation. We understand that time is money.</p>
                <p>While some games level creation can be automated, others require a human touch. Providing the tools necessary to improve level design can increase productivity and save time and money.</p>
            </>),
            icon: <LevelDesignIcon fill="#080a2e" />,
            image: "",
            imageAlt: "Recipe Rescue: Fruit Blast Level Design",
        },
        {
            title: "Marketing Strategy",
            description: (<>
                <p>Every game is different, which is why we work with you to determine the best approach based on your needs.This can range from social media campaigns and working with content creators, to traditional advertising and more.</p>
                <p>Soft launching is another common approach which allows you to gauge game feel before applying more widespread marketing.</p>
            </>),
            icon: <MarketingIcon fill="#03041d" />,
            image: "",
            imageAlt: "Nana, Nina, and Chef in a grape pool",
        },
    ]
}

export type GameDevelopmentServiceData = {
    metaPageTitle: string,
    metaKeywords: string,
    metaDescription: string,
    title: string,
    subtitle: string,
    icon: JSX.Element,
    services: GameDevelopmentService[],
}

export type GameDevelopmentService = {
    title: string,
    description: JSX.Element,
    icon: JSX.Element,
    image: any,
    imageAlt: string,
}