import React from "react"
import Img from "gatsby-image";
import Layout from "../../components/layout/Layout";
import { graphql } from "gatsby"
import { Navigation } from "../../components/navigation/Navigation";
import { HeaderSize, ParallaxHeader } from "../../components/layout/ParallaxHeader";
import { HeaderInfo } from "../../components/layout/HeaderInfo";
import { gameDesignPageData } from "../../content/pageData/GameDesignData";
import { HeaderImageContainer } from "../../components/layout/HeaderImageContainer";
import { Container } from "../../components/layout/Container";
import { ContainerColour, DiagonalContainer, ContainerSize, Direction } from "../../components/layout/DiagonalContainer";
import { LeftContainer } from "../../components/layout/LeftContainer";
import { RightContainer } from "../../components/layout/RightContainer";
import { DiagonalTopContainer } from "../../components/layout/DiagonalTopContainer";
import { ButtonColour, ButtonSize, Button } from "../../components/layout/Button";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
        watermelonMobile: {
            childImageSharp: any;
        }
        watermelon: {
            childImageSharp: any;
        }
        idea: {
            childImageSharp: any;
        }
        research: {
            childImageSharp: any;
        }
        economy: {
            childImageSharp: any;
        }
        gdd: {
            childImageSharp: any;
        }
        levelDesign: {
            childImageSharp: any;
        }
        marketing: {
            childImageSharp: any;
        }
        allMarkdownRemark: {
            edges: [{
                node: {
                    fields: {
                        slug: string
                    }
                    frontmatter: {
                        title: string
                    }
                }
            }]
        }
    }
}

export default ({ data }: PageData) => {
    const title = gameDesignPageData.title;
    const watermelonMobile = data.watermelonMobile.childImageSharp.fluid;
    const watermelon = data.watermelon.childImageSharp.fluid;
    const watermelonSources = [
        watermelonMobile,
        {
            ...watermelon,
            media: `(min-width: 768px)`,
        }
    ]

    return (
        <Layout
            pageTitle={gameDesignPageData.metaPageTitle}
            keywords={gameDesignPageData.metaKeywords}
            description={gameDesignPageData.metaDescription}
            url={"game-development/game-design"}
            footerClassName="game-design-footer"
        >
            <Navigation />
            <ParallaxHeader size={HeaderSize.MEDIUM}>
                <HeaderInfo
                    title={title}
                    icon={gameDesignPageData.icon}
                    subtitle={gameDesignPageData.subtitle}
                    className="game-development-service-header"
                />
            </ParallaxHeader>
            <HeaderImageContainer>
                <Img className="watermelon" fluid={watermelonSources} alt={`Nana, Nina, and Chef eating a watermelon`} />
            </HeaderImageContainer>
            <Container className="non-diagonal-container first-container">
                <LeftContainer
                    icon={gameDesignPageData.services[0].icon}
                    title={gameDesignPageData.services[0].title}
                    description={gameDesignPageData.services[0].description}
                    image={data.idea.childImageSharp.fluid}
                    imageAlt={gameDesignPageData.services[0].imageAlt}
                />
            </Container>
            <DiagonalContainer size={ContainerSize.MEDIUM} colour={ContainerColour.ORANGE} hasRips={false} withPad={true}>
                <RightContainer
                    icon={gameDesignPageData.services[1].icon}
                    title={gameDesignPageData.services[1].title}
                    description={gameDesignPageData.services[1].description}
                    image={data.research.childImageSharp.fluid}
                    imageAlt={gameDesignPageData.services[1].imageAlt}
                />
            </DiagonalContainer>
            <Container className="non-diagonal-container">
                <LeftContainer
                    icon={gameDesignPageData.services[2].icon}
                    title={gameDesignPageData.services[2].title}
                    description={gameDesignPageData.services[2].description}
                    image={data.economy.childImageSharp.fluid}
                    imageAlt={gameDesignPageData.services[2].imageAlt}
                />
            </Container>
            <DiagonalContainer size={ContainerSize.MEDIUM} colour={ContainerColour.BLUE} direction={Direction.LEFT} hasRips={false} withPad={true}>
                <RightContainer
                    icon={gameDesignPageData.services[3].icon}
                    title={gameDesignPageData.services[3].title}
                    description={gameDesignPageData.services[3].description}
                    image={data.gdd.childImageSharp.fluid}
                    imageAlt={gameDesignPageData.services[3].imageAlt}
                />
            </DiagonalContainer>
            <Container className="non-diagonal-container">
                <LeftContainer
                    icon={gameDesignPageData.services[4].icon}
                    title={gameDesignPageData.services[4].title}
                    description={gameDesignPageData.services[4].description}
                    image={data.levelDesign.childImageSharp.fluid}
                    imageAlt={gameDesignPageData.services[4].imageAlt}
                />
            </Container>
            <DiagonalTopContainer size={ContainerSize.MEDIUM}
                colour={ContainerColour.ORANGE} withPad={true}>
                <RightContainer
                    icon={gameDesignPageData.services[5].icon}
                    title={gameDesignPageData.services[5].title}
                    description={gameDesignPageData.services[5].description}
                    image={data.marketing.childImageSharp.fluid}
                    imageAlt={gameDesignPageData.services[5].imageAlt}
                />
                <Button
                    buttonConfig={{
                        colour: ButtonColour.BLACK,
                        size: ButtonSize.LARGE,
                        ctaLabel: "Back",
                        ctaLink: "/game-development",
                        id: "blackGradient",
                        ariaLabel: "Return to Game Development"
                    }}
                />
            </DiagonalTopContainer>
        </Layout>
    );
};



export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        watermelonMobile: file(relativePath: { eq: "assets/images/game-design/watermelon_small.png" }) {
            childImageSharp {
                fluid(maxWidth: 768, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        watermelon: file(relativePath: { eq: "assets/images/game-design/watermelon.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        idea: file(relativePath: { eq: "assets/images/game-design/chest.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        research: file(relativePath: { eq: "assets/images/game-design/research.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        economy: file(relativePath: { eq: "assets/images/game-design/economy.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        gdd: file(relativePath: { eq: "assets/images/game-design/gdd.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        levelDesign: file(relativePath: { eq: "assets/images/game-design/leveldesign.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        marketing: file(relativePath: { eq: "assets/images/game-design/marketing.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`