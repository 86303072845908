

import React from "react";

interface Props {
    fill: string;
}

export const ResearchIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill={fill}>
            <path d="M27.8,25.4l-6.9-6.8c1.4-1.9,2.2-4.2,2.2-6.7c0-6.2-5.1-11.3-11.3-11.3C5.6,0.5,0.5,5.6,0.5,11.8c0,6.2,5.1,11.3,11.3,11.3
            c2.5,0,4.8-0.8,6.6-2.1l6.9,6.8c0.7,0.7,1.8,0.7,2.5,0C28.5,27.1,28.5,26,27.8,25.4z M4,11.8C4,7.5,7.5,4,11.8,4
            c4.3,0,7.8,3.5,7.8,7.8c0,4.3-3.5,7.8-7.8,7.8C7.5,19.6,4,16.1,4,11.8z"/>
        </svg>
    )
}