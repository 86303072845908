import React from "react";

interface Props {
    fill: string;
}

export const LevelDesignIcon: React.FC<Props> = ({ fill }) => {
    return (
        <svg width="29" height="29" viewBox="0 0 29 29" fill={fill}>
            <path d="M12.9,0.8L3.4,6.2c-1,0.6-1.6,1.7-1.6,2.8v10.9c0,1.2,0.6,2.2,1.6,2.8l9.5,5.4c1,0.6,2.3,0.6,3.3,0l9.5-5.4
            c1-0.6,1.6-1.7,1.6-2.8V9.1c0-1.2-0.6-2.2-1.6-2.8l-9.5-5.4C15.1,0.2,13.9,0.2,12.9,0.8z M22.3,20.9l-6.2,3.6c-1,0.6-2.3,0.6-3.3,0
            l-6.2-3.6c-1-0.6-1.6-1.7-1.6-2.8v-7.1c0-1.2,0.6-2.2,1.6-2.8l6.2-3.6c1-0.6,2.3-0.6,3.3,0l6.2,3.6c1,0.6,1.6,1.7,1.6,2.8v7.1
            C24,19.2,23.3,20.3,22.3,20.9z"/>
            <path d="M15.8,8l1.1,2.1c0.2,0.4,0.7,0.7,1.1,0.8l2.4,0.3c1.6,0.2,1.9,1.3,0.8,2.4l-1.7,1.7c-0.3,0.3-0.5,0.8-0.4,1.2l0.4,2.4
            c0.3,1.5-0.7,2.2-2.1,1.5l-2.1-1.1c-0.4-0.2-1-0.2-1.3,0l-2.1,1.1c-1.4,0.7-2.3,0.1-2.1-1.5l0.4-2.4c0.1-0.4-0.1-1-0.4-1.2
            l-1.7-1.7c-1.1-1.1-0.8-2.2,0.8-2.4l2.4-0.3c0.4-0.1,0.9-0.4,1.1-0.8L13.2,8C13.9,6.6,15.1,6.6,15.8,8z"/>
        </svg>
    )
}